<template>
  <div id="works">
    <div>
      <main-menu />
    </div>
    <div
      :id="work.anchor"
      v-for="work in works"
      :key="work.title"
      :style="`background-image: url(${work.background})`"
      class="work"
    >
      <div class="container mx-auto">
        <div class="work-wrapper">
          <div class="headlines nowrap">
            <div class="work-title">
              <P>Title:</P>
              <p>{{ work.title }}</p>
            </div>
            <div class="work-url">
              <p>URL:</p>
              <a :href="work.url">{{ work.url }}</a>
            </div>
            <div class="work-period">
              <p>Production Period:</p>
              <p>{{ work.period }}</p>
            </div>
            <div class="work-tool">
              <p>Tool:</p>
              <p>{{ work.tool }}</p>
            </div>
            <div class="work-role">
              <p>Role:</p>
              <p>{{ work.role }}</p>
            </div>
          </div>
          <div class="work-screen">
            <Splide
              :has-track="false"
              :options="{ rewind: true, perPage: 1 }"
              aria-label="My Favorite Images"
            >
              <div class="macbook-image">
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                  class="macbook-container"
                >
                  <div class="macbook-screen">
                    <SplideTrack>
                      <SplideSlide
                        v-for="(screen, index) in work.screens"
                        :key="index"
                      >
                        <img :src="screen" class="" />
                      </SplideSlide>
                    </SplideTrack>
                  </div>
                </div>

                <div class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <img src="@/assets/img/screens/left-button.svg" />
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <img src="@/assets/img/screens/right-button.svg" />
                  </button>
                </div>
              </div>
              <div class="right-button"></div>
            </Splide>
          </div>
          <div
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-delay="1000"
            class="work-texts relative"
          >
            <div
              class="works-bg hide-tablet"
              :style="`background-image: url(${work.background})`"
            ></div>
            <p>
              {{ work.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "@vue/runtime-core";
import MainMenu from "./../components/MainMenu.vue";
import { useRoute } from "vue-router";
import VueScrollTo from "vue-scrollto";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

const route = useRoute();

onMounted(() => {
  setTimeout(() => {
    if (route.hash) {
      VueScrollTo.scrollTo(route.hash);
    }
  }, 100);
});

const works = [
  {
    anchor: "falcon-1",
    title: "Falcon VvE Management ver.1",
    url: "https://www.falconvvemanagement.nl/",
    period: "2023.1 - 2 (100hrs)",
    tool: "Sketch, Illustrator, Photoshop",
    role: "Website Design",
    screens: [
      require("./../assets/img/screens/falconvve-ver.1_1.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_2.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_3.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_4.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_5.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_6.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_7.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_8.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_9.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_10.jpg"),
      require("./../assets/img/screens/falconvve-ver.1_11.jpg"),
    ],
    text: "オランダにある不動産管理会社のウェブサイトリニューアルにつき、デザインを担当。２バージョン作成し、こちらのバージョン１が採用された。企業イメージをある程度保つため、企業ロゴの青をベースに配色選定を行う。若者でも興味を引くようなポップで遊び心のあるテーマを意識しつつ、プロフェッショナルさを掻き消さないよう工夫した。 ",
    background: require("./../assets/img/backgrounds/falconvve.png"),
  },
  {
    anchor: "falcon-2",
    title: "Falcon VvE Management ver.2",
    url: "https://www.falconvvemanagement.nl/",
    period: "2023.1 - 2 (60hrs)",
    tool: "Sketch, Illustrator, Photoshop",
    role: "Website Design",
    screens: [
      require("./../assets/img/screens/falconvve-ver.2_1.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_2.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_3.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_4.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_5.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_6.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_7.jpg"),
      require("./../assets/img/screens/falconvve-ver.2_8.jpg"),
    ],
    text: "オランダにある不動産会社のウェブサイトリニューアルにつき、デザインを担当。２バージョン作成し、こちらのバージョン２は不採用となった。モダンクラッシックをテーマに、暗めの背景にポイントでオレンジを配色した。全体的にプロフェッショナル且つ洗練された雰囲気に統一した。",
    background: require("./../assets/img/backgrounds/falconvve.png"),
  },
  {
    anchor: "falcon",
    title: "Falcon Accountants",
    url: "https://www.falconaccountants.nl/",
    period: "2022.6 - 7 (50hrs)",
    tool: "Sketch, Illustrator",
    role: "Website Design",
    screens: [
      require("./../assets/img/screens/falconaccountants_1.jpg"),
      require("./../assets/img/screens/falconaccountants_2.jpg"),
      require("./../assets/img/screens/falconaccountants_3.jpg"),
      require("./../assets/img/screens/falconaccountants_4.jpg"),
      require("./../assets/img/screens/falconaccountants_5.jpg"),
      require("./../assets/img/screens/falconaccountants_6.jpg"),
    ],
    text: "オランダにある会計管理事務所のウェブサイトリニューアルを想定し、練習用に作成したサイトデザイン。サービスアイコンを独自で制作するなど、詳細部に拘った。また全体的なカラーのトーンなど統一感を崩さないよう意識して制作した。ウェブサイトのデザインとしては、これが初めて制作したものになる。",
    background: require("./../assets/img/backgrounds/falcon.png"),
  },
  {
    anchor: "tappy",
    title: "Tappy",
    url: "https://www.tappy.cc",
    period: "2022.11 - 12 (50hrs)",
    tool: "Sketch, Illustrator",
    role: "PitchDeck Design",
    screens: [
      require("./../assets/img/screens/tappy_1.png"),
      require("./../assets/img/screens/tappy_2.png"),
      require("./../assets/img/screens/tappy_3.png"),
      require("./../assets/img/screens/tappy_4.png"),
      require("./../assets/img/screens/tappy_5.png"),
      require("./../assets/img/screens/tappy_6.png"),
      require("./../assets/img/screens/tappy_7.png"),
      require("./../assets/img/screens/tappy_8.png"),
      require("./../assets/img/screens/tappy_9.png"),
    ],
    text: "オランダにあるスタートアップ企業で、暗号資産の受送金を行うことのできるアプリの開発において、ピッチデックをデザインした。練習用に作成したもので、実際には本デザインは使用されていない。ブランドカラーやロゴなどが決まっていない段階で制作に着手したため、まずはブランドカラー、テーマ、フォントの選定から取り掛かった。複雑化する暗号資産の受送金を簡素化し、誰でも簡単に使えるアプリという開発目的を念頭に、シンプル且つ遊び心を取り入れたデザインに仕上げた。",
    background: require("./../assets/img/backgrounds/tappy.png"),
  },
  {
    anchor: "rebounce",
    title: "Rebounce",
    url: "https://www.rebounce.club/",
    period: "2022.5 - 6 (30 hrs)",
    tool: "Illustrator",
    role: "Graphic Design (背景画・ロードマップ)",
    screens: [
      require("./../assets/img/screens/rebounce_1.jpg"),
      require("./../assets/img/screens/rebounce_2.jpg"),
      require("./../assets/img/screens/rebounce_3.jpg"),
      require("./../assets/img/screens/rebounce_4.jpg"),
    ],
    text: "オランダのスタートアップ企業で、歩くことで暗号資産を稼ぐことのできるNFTアプリの開発において、ウェブサイトの背景画とロードマップの制作を担当。「フィットネスと暗号資産の融合をコンセプトに、フューチャリスティックなテーマでローポリを主軸としたもの」と依頼を受け、ロードマップはローポリで作成した。同サイト上にある他イラストは別のイラストレーターが担当したもので、統一感が崩れないように配色やローポリの粒度に注意を払った。背景画は、テーマであるフィットネスをイメージさせるアスリートをフューチャリスティック感の出る仕様で作成、配置し、両サイドにポリゴンデザインを入れることで、統一感を保つよう工夫した。暗号資産業界の低迷により、本プロジェクトは打ち切り。現時点で今後の再開目処は立っていない。",
    background: require("./../assets/img/backgrounds/rebounce.png"),
  },
  {
    anchor: "noah",
    title: "Noah's Ark",
    url: "",
    period: "2022.11 (20hrs)",
    tool: "Illustrator",
    role: "Graphic Design (NFT Art)",
    screens: [
      require("./../assets/img/screens/noah_1.jpg"),
      require("./../assets/img/screens/noah_2.jpg"),
    ],
    text: "NFTアートのプロジェクトを検討している知人より依頼を受け、NFTのデザインを担当。ノアの方舟をテーマにしたNFTアートで、方舟に乗船する動物達をデザインした。暗号資産業界の低迷により、本プロジェクトは打ち切り。現時点で今後の再開目処は立っていない。",
    background: require("./../assets/img/backgrounds/noah.png"),
  },
  {
    anchor: "independent",
    title: "Independent Project",
    url: "",
    period: "2022.6 (10hrs)",
    tool: "Sketch, Illustrator, Photoshop",
    role: "Graphic Art",
    screens: [require("./../assets/img/screens/independent_1.jpg")],
    text: "Adobe Illustratorのスキルアップのため練習用に制作。ポリゴンアートに初挑戦した。背景に光を入れ、細部に影と光を配置することで立体感を演出するよう工夫した。",
    background: require("./../assets/img/backgrounds/independent.png"),
  },
];
</script>

<style>
.splide:not(.is-overflow) .splide__pagination {
  display: block !important;
}
</style>

<style scoped>
.works-bg {
  filter: opacity(0.5);
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.container {
  padding: 20px;
}

.work-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 895px;
  margin: auto;
}

.work-title,
.work-url,
.work-period,
.work-tool,
.work-role {
  display: flex;
  justify-content: left;
  margin-bottom: 1px;
}

.headlines {
  align-self: baseline;
  margin-bottom: 20px;
  font-family: "MplusBold";
}

.headlines p {
  margin: 0;
  margin-right: 8px;
}

.work-screen {
  display: flex;
  justify-content: center;
  width: 100%;
}

.left-button,
.right-button {
  align-self: center;
}

.splide,
.splide img {
  width: 100%;
}

.work {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 100%;
  padding-top: 70px;
  margin-bottom: 50px;
}

.macbook-image {
  position: relative;
  flex-grow: 1;
}

.macbook-container {
  position: relative;
  height: 100%;
}

.work-texts {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .work {
    background-image: none !important;
  }
}

@media only screen and (min-width: 990px) {
  .macbook-container {
    background: url("./../assets/img/works/macbook.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 450px;
  }

  .work {
    padding-top: 100px;
    margin-bottom: 200px;
  }

  .macbook-screen {
    display: flex;
    margin: auto;
    position: relative;
    height: 270px;
    overflow: hidden;
    width: 564px;
    height: 355px;
    left: 1px;
    top: 24px;
    background: black;
  }

  .headlines p {
    margin: 0;
    margin-right: 30px;
  }
}

.macbook-image .screen {
  position: absolute;
  top: 35px;
  left: 134px;
  width: 842px;
  height: 532px;
}

button {
  background: none;
}

.left-button img,
.right-button img {
  cursor: pointer;
}
</style>
