<template>
  <router-view />
</template>

<script>
import AOS from "aos";

export default {
  name: "App",
  components: {},
  mounted() {
    setTimeout(AOS.init, 500);
  },
};
</script>

<style></style>
