<template>
  <div class="page">
    <main-menu class="home-menu fade-in-bottom" />

    <div class="block1 wrapper">
      <div class="face">
        <div data-aos="fade" data-aos-duration="2000" class="face-images">
          <div class="faces">
            <a href="/#/about">
              <img
                class="face-original"
                :class="{ hide: isHidden }"
                src="@/assets/img/home/face-hover.png"
              />
              <img
                class="face-hover"
                :class="{ hide: !isHidden }"
                @mouseover="swapImage"
                @mouseleave="swapImage"
                src="@/assets/img/home/face-original.png"
              />
            </a>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            data-aos-delay="1000"
            class="face-texts"
          >
            <img src="./../assets/img/home/face-texts.png" />
          </div>
        </div>
      </div>
    </div>

    <section class="bg-wrapper">
      <div class="container mx-auto">
        <div class="titles">
          <div data-aos="fade-up" data-aos-duration="800" class="title">
            WHAT I MADE
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
            class="sub-title"
          >
            My Artworks
          </div>
        </div>

        <div class="works pointer">
          <div
            @click="gotoWork(artwork)"
            v-for="artwork in artworks"
            :key="artwork"
            class="work"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <div class="overlay">
              <div class="work-title">
                <p class="title-main">{{ artwork.title }}</p>
                <p class="title-category">{{ artwork.category }}</p>
              </div>
            </div>

            <img :src="artwork.art" />

            <div class="hide-desktop font-title">
              <p class="title-main mt-5 m-0">{{ artwork.title }}</p>
              <p class="title-category mt-5 m-0">{{ artwork.category }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="contact" class="footer wrapper">
      <div class="contact-info font-extrabold">
        <div data-aos="zoom-in" data-aos-duration="2000" class="contact-name">
          <h4>GET IN TOUCH</h4>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="800"
          class="contact-text"
        >
          <h4>
            Thank you for viewing my portofolio.<br />Contact me for more info !
            <br />Email: me@chihana.xyz
          </h4>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="1000"
          class="contact-icons"
        >
          <a href="mailto:me@chihana.xyz"
            ><img class="email" src="./../assets/img/home/email.svg"
          /></a>
          <a href="http://www.linkedin.com/in/chihana-a-4a57151b3"
            ><img class="linkedin" src="./../assets/img/home/linkedin.svg"
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MainMenu from "./../components/MainMenu.vue";
import { useRouter, useRoute } from "vue-router";
import VueScrollTo from "vue-scrollto";
import { useRouteHash } from "@vueuse/router";
import { onMounted, watch } from "vue";
import { ref } from "vue";

const router = useRouter();
const route = useRoute();

const isHidden = ref(true);
const swapImage = () => {
  isHidden.value = !isHidden.value;
};
const hash = useRouteHash();

const gotoWork = (artwork) => {
  router.push("/works#" + artwork.anchor);
};

watch(hash, () => {
  VueScrollTo.scrollTo(route.hash);
});

onMounted(() => {
  setTimeout(() => {
    if (route.hash) {
      VueScrollTo.scrollTo(route.hash);
    }
  }, 100);
});

const artworks = [
  {
    title: "Falcon VvE Management ver.1",
    category: "Web Design",
    art: require("./../assets/img/works/work1.png"),
    anchor: "falcon-1",
  },
  {
    title: "Falcon VvE Management ver.2",
    category: "Web Design",
    art: require("./../assets/img/works/work2.png"),
    anchor: "falcon-2",
  },
  {
    title: "Falcon Accountans",
    category: "Web Design",
    art: require("./../assets/img/works/work3.png"),
    anchor: "falcon",
  },
  {
    title: "Tappy",
    category: "PitchDeck Design",
    art: require("./../assets/img/works/work4.png"),
    anchor: "tappy",
  },
  {
    title: "Rebounce",
    category: "Graphic Design",
    art: require("./../assets/img/works/work5.png"),
    anchor: "rebounce",
  },
  {
    title: "Noah's Ark",
    category: "Graphic Design",
    art: require("./../assets/img/works/work6.png"),
    anchor: "noah",
  },
  {
    title: "Independent Project",
    category: "Graphic Art",
    art: require("./../assets/img/works/work7.png"),
    anchor: "independent",
  },
];
</script>

<style scoped>
img {
  max-width: 100%;
}
.home-menu {
  animation-delay: 1.5s;
}
.block1 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.faces {
  width: 80%;
  position: relative;
  margin: auto;
  text-align: left;
}
.faces .face-hover {
  position: absolute;
  left: 0;
}

.faces img {
  width: 100%;
  margin: auto;
  transition: 0.3s all linear;
}

.hide {
  opacity: 0;
}

.face-texts {
  bottom: 30vw;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  animation-delay: 1s;
}

section {
  padding-top: 150px;
  padding-bottom: 250px;
}

.works {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 100px;
}

.bg-wrapper {
  background-color: #ffde00;
}

.contact-name {
  font-size: 21px;
}
.contact-info h4 {
  margin: 0;
}

.footer {
  margin: 85px auto;
}
.work {
  flex-direction: column;
  position: relative;
  display: flex;
  align-items: center;
}

.contact-text {
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0;
  font-size: 12px;
  font-family: MplusBold;
}

.contact-text h4 {
  opacity: 0.6;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.linkedin,
.email {
  cursor: pointer;
  width: 30px;
  opacity: 1;
}

.linkedin:hover,
.email:hover {
  opacity: 0.6;
  transition: all 0.5s ease;
}

@media only screen and (min-width: 768px) {
  .title-main {
    max-width: 350px;
  }
  .bg-wrapper {
    width: 100%;
    background: url(./../assets/img/backgrounds/homeBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  .contact-text {
    font-size: 14px;
  }

  .faces {
    width: 40vw;
    max-width: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .work:hover .overlay {
    cursor: pointer;
    visibility: visible;
    opacity: 0.9;
  }

  .face-texts {
    bottom: 300px;
  }

  .works {
    margin-top: 100px;
  }
}

.overlay {
  display: flex;
  visibility: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  background: #5d572c;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 50%;
  opacity: 0;
  color: #fff;
  font-family: "Pacifico";
  transition: all 0.5s ease;
}

.title-main {
  line-height: 40px;
  font-size: 40px;
}

.title-category {
  font-size: 20px;
  line-height: 20px;
}
</style>
