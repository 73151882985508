import { createRouter, createWebHashHistory } from "vue-router";
import { createApp } from "vue";
import VueScrollTo from "vue-scrollto";

// 1. Define route components.
// These can be imported from other files
import HomePage from "@/pages/home-page";
import AboutPage from "@/pages/about-page";
import WorksPage from "@/pages/works-page";
import App from "./App.vue";
import("@/assets/css/portfolio.css");
import "aos/dist/aos.css";

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  {
    path: "/",
    component: HomePage,
    meta: {
      title: "Chihana | Home",
    },
  },
  {
    path: "/about",
    component: AboutPage,
    meta: {
      title: "Chihana | About",
    },
  },
  {
    path: "/works",
    component: WorksPage,
    meta: {
      title: "Chihana | Works",
    },
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
  scrollBehavior() {
    // return desired position
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

// 5. Create and mount the root instance.
const app = createApp(App);
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router);

app.use(VueScrollTo);

app.mount("#app");
