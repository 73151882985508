<template>
  <div class="header" ref="menu">
    <nav>
      <div class="wrapper">
        <div class="display-flex">
          <div class="show-menu font-bold" :class="{ show: showMenu }">
            <ul>
              <li><a href="/">HOME</a></li>
              <li>
                <a href="/#/about">ABOUT</a>
              </li>
              <li>
                <a href="/#/works">WORKS</a>
              </li>
              <li>
                <router-link to="/#contact">CONTACT</router-link>
              </li>
            </ul>
          </div>
          <img
            @click="showMenu = !showMenu"
            src="./../assets/img/home/menu.png"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
const showMenu = ref(false);
const menu = ref(null);

onClickOutside(menu, () => (showMenu.value = false));
</script>

<style>
.header {
  position: fixed;
  top: 0px;
  z-index: 101;
  width: 100%;
  left: 0px;
}
.display-flex {
  display: flex;
  justify-content: right;
}
.header a {
  height: 14px;
  cursor: pointer;
  text-decoration: none;
  color: #5d572c;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.header a:hover {
  color: #ffde00;
  transition: all 0.5s ease;
}
.show-menu {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  background: #fff5b2;
}
.header img {
  position: relative;
  align-self: baseline;
  z-index: 100;
  cursor: pointer;
  top: 40px;
}
.header ul {
  list-style-type: none;
  padding: 0;
}
.header li {
  font-size: 20px;
  margin: 10px 40px;
}

/* mobile below */
@media screen and (max-width: 768px) {
  .show-menu {
    display: none;
  }
  .show.show-menu {
    display: block;
  }
}

/* mobile up */
@media screen and (min-width: 768px) {
  .show-menu {
    display: flex;
    /* position: relative; */
    background: rgba(255, 255, 255, 0.8);
    justify-content: space-around;
  }
  .show-menu ul {
    display: flex;
    margin: 0;
    /* margin-right: 20px; */
  }
  .show-menu li {
    font-size: 16px;
  }

  .header img {
    display: none;
  }
}
</style>
