<template>
  <div class="hobby-container">
    <div class="hobbies">
      <div class="circle--slider">
        <div class="rotate--circle">
          <div class="spinner">
            <div
              v-for="hobby in hobbies"
              :class="{ active: hobby.isActive }"
              @click="setActive(hobby)"
              :key="hobby.name"
            >
              <img :src="hobby.image" />
            </div>
          </div>
          <div class="animate-wrapper">
            <div
              v-if="activeHobby"
              class="background"
              :style="`background-image: url(${activeHobby.background})`"
            ></div>
            <div class="static-background"></div>

            <div v-if="activeHobby" class="animate">
              <div class="animate-img">
                <div class="animate-img__in">
                  <div class="animate-more">
                    <div class="animate-title">
                      <h3>{{ activeHobby.name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, onBeforeUnmount, onMounted } from "vue";
const emit = defineEmits(["onUpdate"]);

const updateValue = () => {
  emit("onUpdate", activeHobby.value);
};

const AUTOPLAY_COUNTDOWN_TIME = 5000;
let interval = null;

const hobbies = ref([
  {
    index: 0,
    name: "FASHION",
    image: require("@/assets/img/hobbies/fashion.png"),
    background: require("@/assets/img/hobbies/photo-fashion.png"),
    title: "is the armor to survive the reality of everyday life.",
    text: "ファッションとは、日々立ちはだかる現実を生き抜くための鎧である。",
  },
  {
    index: 1,
    name: "SHOPPING",
    image: require("@/assets/img/hobbies/shopping.png"),
    background: require("@/assets/img/hobbies/photo-shopping.png"),
    title: "My favorite sport is tracking my online orders.",
    text: "私の好きなスポーツは、ネットショッピングで購入した注文を追跡すること。",
  },
  {
    index: 2,
    name: "MOVIES",
    image: require("@/assets/img/hobbies/movie.png"),
    background: require("@/assets/img/hobbies/photo-movie.png"),
    title: "are windows to other parts of the world",
    text: "映画とは、別世界へつながる窓のようなもの。",
  },
  {
    index: 3,
    name: "TRAVEL",
    image: require("@/assets/img/hobbies/travel.png"),
    background: require("@/assets/img/hobbies/photo-travel.png"),
    title: "The goal is to die with memories not dreams.",
    text: "人生のゴールは、夢のままで終わるのではなく、記憶に残して終えること。",
  },
  {
    index: 4,
    name: "MUSIC",
    image: require("@/assets/img/hobbies/music.png"),
    background: require("@/assets/img/hobbies/photo-music.png"),
    title: "gives color to the air of the moment.",
    text: "音楽とはその瞬間に色を与えるもの。",
  },
  {
    index: 5,
    name: "ART",
    image: require("@/assets/img/hobbies/art.png"),
    background: require("@/assets/img/hobbies/photo-art.png"),
    title: "is not what you see, but what you make others see",
    text: "アートとは、見るものではなく、見せるもの。",
  },
  {
    isActive: true,
    index: 6,
    name: "GYM",
    image: require("@/assets/img/hobbies/gym.png"),
    background: require("@/assets/img/hobbies/photo-gym.png"),
    title: "the best project you'll ever work on is you.",
    text: "生涯取り組むべき最高のプロジェクトは、自分自身である。",
  },
]);

const rotateCircle = (deg) => {
  const circle = document.querySelector(".spinner");
  circle.style.transform = `rotate(${deg}deg)`;
};

const activeHobby = computed(() =>
  hobbies.value.find((hobby) => hobby.isActive)
);

onMounted(() => {
  interval = setInterval(() => goNext(), AUTOPLAY_COUNTDOWN_TIME);
  goNext();
});

const resetInterval = () => {
  clearInterval(interval);
  interval = setInterval(() => goNext(), AUTOPLAY_COUNTDOWN_TIME);
};

onBeforeUnmount(() => {
  clearInterval(interval);
});

const goNext = () => {
  //get index of the current active element
  const index = hobbies.value.findIndex((hobby) => hobby.isActive);
  if (index !== -1) {
    if (index == 0) {
      setActive(hobbies.value[hobbies.value.length - 1]);
    } else {
      setActive(hobbies.value[index - 1]);
    }
  }
};

const setActive = (item) => {
  hobbies.value.forEach((elem) => {
    elem.isActive = false;
  });

  item.isActive = true;

  resetInterval();
  updateValue();

  rotateCircle(140 - item.index * (360 / 7));
};
</script>

<style>
.hobbies {
  width: 100%;
  height: 100%;
}

.circle--slider {
  width: 100%;
  height: 100%;
  position: relative;
  border: 3px solid rgba(93, 87, 44, 0.5);
  border-radius: 100%;
}

.rotate--circle {
  height: 100%;
}

.hobby-container {
  margin: auto;
  overflow-x: hidden;
  overflow-y: clip;
  width: 225px;
  height: 225px;
  padding: 40px;
}

.spinner {
  transition: 0.3s all;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.spinner img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  transform: rotate(220deg);
  visibility: visible;
  filter: blur(2px);
}

.spinner > div.active img {
  width: 70px;
  filter: blur(0px);
}

.spinner > div {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
}

.spinner :nth-child(2) {
  transform: rotateZ(51.42deg);
}

.spinner :nth-child(3) {
  transform: rotateZ(102deg);
}

.spinner :nth-child(4) {
  transform: rotateZ(154deg);
}

.spinner :nth-child(5) {
  transform: rotateZ(205deg);
}

.spinner :nth-child(6) {
  transform: rotateZ(257deg);
}

.spinner :nth-child(7) {
  transform: rotateZ(308deg);
}

.static-background {
  width: 101%;
  left: -3px;
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -5;
  opacity: 0.8;
  background-image: url("../assets/img/backgrounds/circle-bg.png");
}

.animate-wrapper .background {
  width: 87%;
  height: 91%;
  left: 4%;
  top: 4%;
  z-index: -5;
  border-radius: 55%;
  position: absolute;
  background-size: cover;
  opacity: 1;
}

.circle--slider .animate-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
}

.circle--slider .animate-title {
  font-family: "Pacifico";
  color: #5d572c;
  line-height: 1;
  font-size: 18px;
}

.animate {
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
}

@media screen and (min-width: 768px) {
  .hobby-container {
    min-width: 350px;
    height: 350px;
    padding: 0;
    overflow-x: visible;
    overflow-y: visible;
  }
  .spinner img {
    width: 70px;
  }
  .spinner > div.active img {
    width: 100px;
  }
  .circle--slider .animate-title {
    font-size: 25px;
  }
}

@media screen and (min-width: 1280px) {
  .hobby-container {
    width: 500px;
    height: 500px;
    margin-left: -100px;
  }
  .spinner img {
    width: 100px;
  }
  .spinner > div.active img {
    width: 130px;
  }
  .circle--slider .animate-title {
    font-size: 30px;
  }
}

@media screen and (min-width: 1536px) {
  .hobby-container {
    width: 650px;
    height: 650px;
    margin: 0;
  }
}
</style>
