<template>
  <div>
    <div class="about-bg1">
      <div class="about-bg2">
        <main-menu class="" />
        <div class="block1 wrapper">
          <div class="block1-title">
            <div class="titles">
              <div data-aos="fade-right" data-aos-duration="800" class="title">
                ABOUT ME
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="100"
                class="sub-title"
              >
                My Background
              </div>
            </div>
          </div>
          <div class="about-me-contents">
            <div class="about-me-texts">
              <div
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="500"
                class="intro"
              >
                <span>
                  Born and raised in Yamanashi, currently living in Tokyo. Lived
                  in the United States and the Netherlands. For my education and
                  work history, check out my bio below ! For further info please
                  go to my Linkedin via
                  <router-link to="/#contact">contact</router-link>.<br /><br />
                  山梨生まれ、山梨育ち。現在は東京に在住。アメリカ、オランダ在住経験あり。簡単な学歴、職務経歴は下記を参照ください。詳細については、<router-link
                    to="/#contact"
                    >contact</router-link
                  >よりLinkedinをご覧ください。
                </span>
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="1000"
                class="bio"
              >
                <img src="./../assets/img/about/bio.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block2 wrapper">
        <div class="hobby-contents" style="">
          <div class="hide-tablet">
            <div class="titles">
              <div
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="100"
                class="title title-for-hobby"
              >
                HOBBIES
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="200"
                class="sub-title"
              >
                My Interests
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-delay="500"
              class="hobby-headline-tablet"
            >
              <span
                >Here are my favorite things.
                <br />私の好きなモノ、好きなコト。</span
              >
            </div>
          </div>
          <HobbySlider
            @on-update="setActive"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-delay="800"
          />
          <div class="hobby-texts" style="">
            <div class="block2-title hidden show-tablet">
              <div class="titles">
                <div data-aos="fade-left" data-aos-duration="800" class="title">
                  HOBBIES
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="100"
                  class="sub-title"
                >
                  My Interests
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-duration="800"
                data-aos-delay="300"
                class="hobby-headline"
              >
                <span
                  >Here are my favorite things.
                  <br />私の好きなモノ、好きなコト。</span
                >
              </div>
            </div>
            <div
              v-if="activeItem"
              data-aos="zoom-in"
              data-aos-duration="1000"
              data-aos-delay="900"
            >
              <h2>{{ activeItem.title }}</h2>
              <span>{{ activeItem.text }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-bg3">
      <div class="block3 wrapper">
        <div class="block3-title">
          <div class="titles">
            <div data-aos="fade-up" data-aos-duration="800" class="title">
              WHAT I CAN DO
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              class="sub-title"
            >
              My Skills
            </div>
          </div>
        </div>
        <div
          class="skill-headline"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="400"
        >
          <span
            >Tools and languages that I taught myslef to use for the last 2
            years.<br />直近2年間に独学で習得したツールと言語</span
          >
        </div>
        <div class="skills font-extrabold">
          <div
            v-for="skill in skills"
            :key="skill"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-delay="600"
            class="skill"
          >
            <img :src="skill.image" />
            <span class="skill-title">{{ skill.title }}</span>
            <span class="skill-percentage">{{ skill.percentage }}</span>
            <span class="skill-year">{{ skill.year }}</span>
            <span
              class="skill-comment"
              v-for="comment in skill.comments"
              :key="comment"
            >
              {{ comment }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import HobbySlider from "@/components/HobbySlider.vue";
import { ref } from "vue";
import MainMenu from "../components/MainMenu.vue";

const activeItem = ref(null);

const setActive = (item) => {
  activeItem.value = item;
};

const skills = ref([
  {
    image: require("./../assets/img/skills/skill-1.png"),
    title: "Sketch",
    percentage: "60%",
    year: "2 year",
    comments: [
      "・使用用途：",
      "　WEBデザイン",
      "・基本機能操作問題なし",
      "・使用頻度最多",
    ],
  },
  {
    image: require("./../assets/img/skills/skill-2.png"),
    title: "Illustrator",
    percentage: "50%",
    year: "1.5 year",
    comments: [
      "・使用用途：",
      "　グラフィックデザイン",
      "　イラスト制作",
      "　アイコン制作",
      "・基本機能操作問題なし",
    ],
  },
  {
    image: require("./../assets/img/skills/skill-3.png"),
    title: "Photoshop",
    percentage: "30%",
    year: "0.5 year",
    comments: ["・使用用途：", "　写真加工", "・ビギナーレベル"],
  },
  {
    image: require("./../assets/img/skills/skill-4.png"),
    title: "CSS",
    percentage: "50%",
    year: "1.5 year",
    comments: ["・使用エディタ：", "　VSCode", "・基本的操作問題なし"],
  },
  {
    image: require("./../assets/img/skills/skill-5.png"),
    title: "HTML",
    percentage: "50%",
    year: "1.5 year",
    comments: ["・使用エディタ：", "　VSCode", "・基本的操作問題なし"],
  },
  {
    image: require("./../assets/img/skills/skill-6.png"),
    title: "JavaScript",
    percentage: "20%",
    year: "0.5 year",
    comments: ["・使用エディタ：", "　VSCode", "・ビギナーレベル"],
  },
]);
</script>

<style scoped>
.about-bg2 {
  background-color: #ffde00;
  padding: 80px 0;
}
.block1-title {
  text-align: left;
}

.intro {
  font-size: 16px;
  text-align: left;
  padding-bottom: 50px;
}

.bio {
  text-align: left;
}

.block2-title {
  text-align: right;
}

.title-for-hobby {
  color: #ffde00;
}

.hobby-headline,
.skill-headline {
  font-size: 16px;
}
.hobby-texts {
  max-width: 600px;
  font-size: 16px;
}

.hobby-texts h2 {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Pacifico";
}

.block2.wrapper {
  padding-top: 50px;
  padding-bottom: 70px;
}

.block2 {
  padding-top: none;
}
.skills {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 50px;
}

.skill-percentage,
.skill-year {
  font-size: 28px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.skill-year {
  margin-bottom: 20px;
}

.skill-comment {
  font-size: 16px;
  display: flex;
  justify-content: left;
  width: 100%;
}

.skill-title {
  font-family: "Pacifico";
  font-size: 35px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.skill {
  margin-bottom: 80px;
}

img {
  max-width: 100%;
  height: auto;
}

.about-bg3 {
  background: #ffde00;
  padding-top: 50px;
}

.hobby-contents {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

@media screen and (min-width: 768px) {
  .intro {
    font-size: 20px;
  }

  .hobby-contents {
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    align-items: start;
  }

  .hobby-texts h2 {
    font-size: 1.5em;
    text-align: right;
  }
  .hobby-texts {
    text-align: right;
    font-size: 20px;
  }
  .hobby-headline,
  .skill-headline {
    font-size: 20px;
  }

  .block2.wrapper {
    padding: 80px;
    margin: 150px 0;
  }

  .block3.wrapper {
    padding-top: 50px;
  }
}

/* for large desktops */
@media screen and (min-width: 1280px) {
  .about-bg2 {
    background: url(./../assets/img/backgrounds/about-bg1.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }
  .about-bg1 {
    overflow: hidden;
    background: url(./../assets/img/backgrounds/about-bg2.png);
    background-repeat: no-repeat;
    background-position: right;
    /* background-size: contain; */
    background-size: 650px;
  }

  .block3.wrapper {
    padding-top: 100px;
  }

  .intro {
    width: 60%;
  }

  .bio img {
    max-width: 50%;
  }
  .hobby-texts {
    text-align: right;
  }
  .hobby-contents {
    justify-content: space-between;
  }

  .hobby-headline {
    padding-bottom: 30px;
  }
  .skill {
    max-width: 180px;
    text-align: left;
  }
}
@media screen and (min-width: 768px) {
  .block1 {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1536px) {
  .hobby-contents {
    justify-content: right;
  }
}

@media screen and (min-width: 1920px) {
  .hobby-contents {
    gap: 300px;
  }
}
</style>
